import { createTheme } from '@material-ui/core';
import { green, orange, red } from '@material-ui/core/colors';
import createPalette from '@material-ui/core/styles/createPalette';

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        danger: Palette['primary'];
        success: Palette['primary'];
        warning: Palette['warning'];
    }
    interface PaletteOptions {
        success?: PaletteOptions['primary'];
        danger?: PaletteOptions['primary'];
        warning?: PaletteOptions['warning'];
    }
}

const augmentColor = createPalette({}).augmentColor;

export const theme = createTheme({
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*': {
                    boxSizing: 'border-box',
                    '--toastify-color-light': '#fff',
                    '--toastify-color-dark': '#121212',
                    '--toastify-color-info': '#e5f6fd',
                    '--toastify-color-success': '#edf7ed',
                    '--toastify-color-warning': '#fff4e5',
                    '--toastify-color-error': '#fdeded',
                    '--toastify-text-color-info': '#014361',
                    '--toastify-text-color-success': '#1e4620',
                    '--toastify-text-color-warning': '#663c00',
                    '--toastify-text-color-error': '#5f2120',
                },
                body: {
                    WebkitFontSmoothing: 'antialiased',
                    MozOsxFontSmoothing: 'grayscale',
                    margin: 0,
                    color: '#2e3538',
                    backgroundColor: '#fff',
                },
                code: {
                    fontFamily: 'source-code-pro, "Menlo", "Monaco", "Consolas", "Courier New", monospace',
                },
                '.MuiAlert-message': {
                    wordBreak: 'break-word',
                },
                '.Toastify__toast--info': {
                    background: '#3498db',
                },
                '.Toastify__toast--success': {
                    background: '#07bc0c',
                },
                '.Toastify__toast--warning': {
                    background: '#f1c40f',
                },
                '.Toastify__toast--error': {
                    background: '#e74c3c',
                },
            },
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                },
            },
        },
        MuiIconButton: {
            sizeSmall: {
                padding: '6px',
            },
        },
        MuiList: {
            root: {
                '& .MuiList-root .MuiListItem-root': {
                    paddingLeft: '32px',
                },
            },
        },
        MuiDialog: {
            paper: {
                minWidth: '600px',
                maxWidth: 'calc(100vw - 20rem)',
                padding: '1rem',
                '& .MuiDialogTitle-root': {
                    padding: '0',
                    '&:not(:last-child)': {
                        marginBottom: '1rem',
                    },
                },
                '& .MuiDialogContent-root': {
                    '&:not(:last-child)': {
                        marginBottom: '1rem',
                    },
                },
                '& .MuiDialogActions-root': {
                    padding: '0',
                },
            },
        },
        MuiTypography: {
            h5: {
                fontWeight: 500,
            },
        },
        MuiFormControl: {
            root: {
                '&.MuiTextField-root': {
                    '&:hover': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#009FE3',
                        },
                    },
                    '& .Mui-error:hover': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#f44336',
                        },
                    },
                },
            },
        },
    },
    palette: {
        primary: {
            light: '#e4f7ff',
            main: '#009FE3',
            dark: '#007aae',
            contrastText: '#fff',
        },
        secondary: {
            main: '#58595B',
            light: '#A7A9AC',
            dark: '#231F20',
            contrastText: '#fff',
        },
        success: augmentColor({
            main: green[700],
        }),
        danger: augmentColor({
            main: red[700],
        }),
        warning: augmentColor({
            main: orange[500],
        }),
        text: {
            primary: '#2e3538',
        },
    },
    typography: {
        htmlFontSize: 16,
        fontSize: 16,
        fontFamily: [
            '-apple-system',
            '"BlinkMacSystemFont"',
            '"Segoe UI"',
            '"Roboto"',
            '"Oxygen"',
            '"Ubuntu"',
            '"Cantarell"',
            '"Fira Sans"',
            '"Droid Sans"',
            '"Helvetica Neue"',
            'sans-serif',
        ].join(','),
    },
});

export const measurements = {
    sidebar: {
        width: 240
    },
    content: {
        width: 960
    }
}
