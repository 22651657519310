import IDataFetcher from './IDataFetcher';
import { Blueprint } from './models/Blueprint';
import { BlueprintOverview } from './models/BlueprintOverview';
import { Changeset } from './models/Changeset';
import {
    AllowedValuesAttributeConstraint,
    Attribute,
    AttributeConstraint,
    AttributeSearchOption,
    Entity,
    EntityList,
    Relation,
} from './models/Entity';
import { Project, ProjectList } from './models/Project';
import { Release } from './models/Release';
import { User } from './models/User';
import { CreateOrganization, Organization, OrganizationList } from './models/Organization';
import { HttpError, HttpJsonError } from './HttpError';
import { User as OidcUser } from 'oidc-client-ts';
import { Member } from './models/Member';
import {
    HalList,
    WithoutHal,
    resolveTemplateRequired as resolveTemplate,
    templateLink,
    TypedLink,
    unsafeCastLink,
    HalForm,
} from '../hal';
import { ExpressionDto, PolicyCreationDto, PolicyDto, PolicyUpdateDto } from './models/PolicyDto';
import { Application, ApplicationConfig, ApplicationHealth, ApplicationWebapp } from './models/Application';
import { Deployment } from './models/Deployment';
import { Zone } from './models/Zone';
import { EventHandlerCreationDto, EventHandlerDto, EventHandlerUpdateDto } from './models/EventHandlerDto';
import { OrganizationInvite, OrganizationInviteList, UserInvite } from './models/Invite';
import { IamRealm, IamRealmOverview } from './models/IamRealm';
import { IamCreateUser, IamEditUser, IamUser, IamUserGroup, IamUserGroupList, IamUserList } from './models/IamUser';
import { IamCreateGroup, IamGroup, IamGroupList, IamGroupMember, IamGroupMemberList } from './models/IamGroup';
import { IamAttribute, IamAttributeList, IamCreateAttribute } from './models/IamAttribute';
import { convertArrayToUriList } from '../helpers';
import { PolicyConditionSuggestion, PolicyConditionSuggestionDescription } from './models/PolicyConditionSuggestion';
import { Feature } from './models/Feature';
import { ReactFlowJsonObject } from 'reactflow';
import { Message, MessageList, Thread, ThreadList } from './models/Assistant';

export default class DataFetcher implements IDataFetcher {
    private readonly baseUrl: string;

    private readonly authority: string;
    private readonly clientId: string;

    public constructor(baseUrl: string, oidcAuthority: string, oidcClientId: string) {
        this.baseUrl = baseUrl;

        this.authority = oidcAuthority;
        this.clientId = oidcClientId;
    }

    public getBaseUrl(): string {
        return this.baseUrl;
    }

    public async getEntityList(blueprint: Blueprint): Promise<EntityList> {
        const url = blueprint._links.entities.href;
        const jsonData = await this.fetchJson(url);
        // backwards compatibility with back when we just returned []
        if (jsonData._embedded === undefined || jsonData._embedded.entities === undefined) {
            return {
                ...jsonData,
                _embedded: { entities: [] },
            };
        }
        return jsonData;
    }

    public async getEntity(entityRef: Entity): Promise<Entity> {
        const url = entityRef._links.self.href;
        return await this.fetchJson<Entity>(url);
    }

    public async addEntity(entityRefList: EntityList, entityName: string, entityDescription: string): Promise<Entity> {
        const { url, method } = resolveTemplate(entityRefList, 'default').request;
        return await this.fetchJson<Entity>(url, {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: entityName,
                description: entityDescription,
            }),
        });
    }

    public async patchEntity(entity: Entity, patch: Partial<WithoutHal<Entity>>): Promise<Entity> {
        const { url, method } = resolveTemplate(entity, 'default').request;
        return await this.fetchJson<Entity>(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(patch),
        });
    }

    public async deleteEntity(entity: Entity): Promise<void> {
        const { url, method } = resolveTemplate(entity, 'delete').request;
        await this.fetch(url, { method: method });
    }

    public async getAttribute(entity: Entity, attribute: string): Promise<Attribute> {
        const url = templateLink(entity._links.attribute!.href, {
            attributeName: attribute,
        });
        return await this.fetchJson<Attribute>(url);
    }

    public async addAttribute(entity: Entity, attribute: WithoutHal<Attribute>): Promise<Attribute> {
        const { url, method } = resolveTemplate(entity, 'addAttribute').request;
        return await this.fetchJson(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(attribute),
        });
    }

    public async patchAttribute(attribute: Attribute, patch: Partial<WithoutHal<Attribute>>): Promise<Attribute> {
        const { url, method } = resolveTemplate(attribute, 'default').request;
        return await this.fetchJson(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(patch),
        });
    }

    public async renameAttribute(attribute: Attribute, newName: string): Promise<Attribute> {
        const { url, method } = resolveTemplate(attribute, 'default').request;
        return await this.fetchJson<Attribute>(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: newName }),
        });
    }

    public async deleteAttribute(attribute: Attribute): Promise<Entity> {
        const { url, method } = resolveTemplate(attribute, 'delete').request;
        return await this.fetchJson<Entity>(url, {
            method,
        });
    }

    public async deleteConstraint(constraint: AttributeConstraint): Promise<void> {
        const { url, method } = resolveTemplate(constraint, 'delete').request;
        await this.fetch(url, {
            method,
        });
    }

    public async setAllowedValuesConstraint(
        form: HalForm<AllowedValuesAttributeConstraint>,
        constraint: WithoutHal<AllowedValuesAttributeConstraint>,
    ): Promise<void> {
        const { url, method } = form.request;
        await this.fetch(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(constraint),
        });
    }

    public async enableRequiredConstraint(attribute: Attribute): Promise<void> {
        const { url, method } = resolveTemplate(attribute, 'enable-required').request;
        await this.fetch(url, {
            method,
        });
    }

    public async enableUniqueConstraint(attribute: Attribute): Promise<void> {
        const { url, method } = resolveTemplate(attribute, 'enable-unique').request;
        await this.fetch(url, {
            method,
        });
    }

    public async enableExactSearch(attribute: Attribute): Promise<void> {
        const { url, method } = resolveTemplate(attribute, 'enable-exact-search').request;
        await this.fetch(url, {
            method,
        });
    }

    public async disableSearchOption(searchOption: AttributeSearchOption): Promise<void> {
        const { url, method } = resolveTemplate(searchOption, 'delete').request;
        await this.fetch(url, {
            method,
        });
    }
    public async enablePrefixSearch(attribute: Attribute): Promise<void> {
        const { url, method } = resolveTemplate(attribute, 'enable-prefix-search').request;
        await this.fetch(url, {
            method,
        });
    }

    public async getRelation(entity: Entity, relation: string): Promise<Relation> {
        const url = templateLink(entity._links.relation!.href, {
            relationName: relation,
        });
        return await this.fetchJson<Relation>(url);
    }

    public async addRelation(entity: Entity, relation: WithoutHal<Relation>): Promise<Relation> {
        const { url, method } = resolveTemplate(entity, 'addRelation').request;
        return await this.fetchJson(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(relation),
        });
    }

    public async deleteRelation(relation: Relation): Promise<Entity> {
        const { url, method } = resolveTemplate(relation, 'delete').request;
        return await this.fetchJson<Entity>(url, {
            method,
        });
    }

    public async patchRelation(relation: Relation, patch: Partial<WithoutHal<Relation>>): Promise<Relation> {
        const { url, method } = resolveTemplate(relation, 'default').request;
        return await this.fetchJson<Relation>(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(patch),
        });
    }

    public async getBlueprintOverview(project: Project): Promise<BlueprintOverview[]> {
        const jsonData = await this.fetchJson<HalList<BlueprintOverview, 'blueprints'>>(
            unsafeCastLink(project._links['blueprints-overview'].href),
        );
        return jsonData._embedded?.blueprints ?? [];
    }

    public async getBlueprints(project: Project): Promise<Blueprint[]> {
        const jsonData = await this.fetchJson(project._links.blueprints.href);
        return jsonData._embedded?.blueprints ?? [];
    }

    public async getBlueprint(overview: BlueprintOverview): Promise<Blueprint> {
        return await this.fetchJson<Blueprint>(overview._links.self.href);
    }

    public async getStagedHistory(blueprint: Blueprint): Promise<readonly Changeset[]> {
        let changesets: Changeset[] = [];
        let requestURI = blueprint._links['staged-history']?.href;
        while (requestURI) {
            const stagedHistory = await this.fetchJson(requestURI!);
            changesets.push(...(stagedHistory._embedded?.changesets ?? []));

            if (stagedHistory._links?.next?.href) {
                requestURI = stagedHistory._links?.next?.href;
            } else {
                break;
            }
        }

        return changesets;
    }

    public async addRelease(
        blueprint: Blueprint,
        changeset: TypedLink<Changeset>,
        label: string,
        message?: string,
    ): Promise<Release> {
        const { url, method } = resolveTemplate(blueprint, 'createRelease').request;
        return await this.fetchJson(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ changeset, label, message }),
        });
    }

    public async getChangeset(changeset: TypedLink<Changeset>): Promise<Changeset> {
        return await this.fetchJson(changeset);
    }

    public async getChangesetAncestry(
        changeset: TypedLink<Changeset> | Changeset,
        untilAncestor?: TypedLink<Changeset>,
    ): Promise<readonly Changeset[]> {
        if (typeof changeset === 'string') {
            changeset = await this.getChangeset(changeset);
        }
        const changesetsAncestry: Changeset[][] = [];
        let ancestryUri = templateLink(changeset._links.ancestry.href, {});
        while (ancestryUri) {
            const ancestry = await this.fetchJson(ancestryUri);
            const changesets = ancestry._embedded?.changesets ?? [];
            const untilAncestorIndex = changesets.findIndex(
                (cs) => cs._links.self.href === untilAncestor || cs._links.bookmark.href === untilAncestor,
            );
            if (untilAncestorIndex === -1) {
                changesetsAncestry.push(changesets);
            } else {
                // If our parent is found in this slice of history, we should only return up until the parent
                // and stop our recursion
                changesetsAncestry.push(changesets.slice(0, untilAncestorIndex));
                break;
            }

            if (ancestry._links.next) {
                ancestryUri = ancestry._links.next.href;
            } else if (untilAncestor) {
                // If we have an ancestor, and we got to here, that means our ancestor was not found at all
                // We have no changesets to give in that case
                return [];
            } else {
                break;
            }
        }

        return ([] as Changeset[]).concat(...changesetsAncestry);
    }

    public async getDownloadUrlForChangeset(changeset: TypedLink<Changeset>): Promise<string> {
        return this.getBaseUrl() + 'codegen/starter.zip?changeset=' + changeset;
    }

    public async getProjects(org: Organization): Promise<ProjectList> {
        const url = org._links.projects.href;
        return await this.fetchJson(url);
    }

    public async addProject(projectList: ProjectList, projectName: string): Promise<Project> {
        const { url, method } = resolveTemplate(projectList, 'default').request;
        return await this.fetchJson(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: projectName }),
        });
    }

    public async getReleasesForBlueprint(blueprint: Blueprint): Promise<Release[]> {
        const jsonData = await this.fetchJson(blueprint._links.releases.href);
        return jsonData._embedded?.releases ?? [];
    }

    public async getReleasesForProject(project: Project): Promise<Release[]> {
        const jsonData = await this.fetchJson(project._links.releases.href);
        const releaseList = jsonData._embedded?.releases ?? [];
        const releases = releaseList.sort(
            (a: Release, b: Release) => new Date(b.created).getTime() - new Date(a.created).getTime(),
        );
        return releases;
    }

    public async getOpenApiSpecForRelease(release: Release): Promise<string> {
        const yamlData = await this.fetch(
            this.baseUrl + 'codegen/openapi.yml?changeset=' + release._links.changeset.href,
        );
        const yamlText = await yamlData.text();
        return yamlText;
    }

    public async getUser(userLink?: TypedLink<User>): Promise<User> {
        return await this.fetchJson(userLink ?? unsafeCastLink(`${this.baseUrl}users/me`));
    }

    public async getOrganizations(user: User): Promise<OrganizationList> {
        return await this.fetchJson(user._links.organizations!.href);
    }

    public async getOrganizationMembers(org: Organization): Promise<Member[]> {
        const jsonData = await this.fetchJson(org._links.members.href);
        return jsonData._embedded?.members ?? [];
    }

    public async addOrganization(organization: CreateOrganization): Promise<Organization> {
        const user = await this.getUser();
        const data: OrganizationList = await this.fetchJson(user._links.organizations!.href);
        const { request } = resolveTemplate(data, 'default');

        const jsonData = await this.fetchJson(request.url, {
            method: request.method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                display_name: organization.display_name,
                slug: organization.slug,
            }),
        });
        return jsonData;
    }

    public async getPoliciesForEntity(blueprint: Blueprint, entity: string): Promise<PolicyDto[]> {
        const url = templateLink(blueprint._links.policies.href, {
            entity: entity,
        });
        const jsonData = await this.fetchJson<{ policies: PolicyDto[] }>(unsafeCastLink(url));
        return jsonData.policies ?? [];
    }

    public async getPolicy(blueprint: Blueprint, id: string): Promise<PolicyDto> {
        const url = templateLink(blueprint._links.policy.href, {
            policyId: id,
        });
        const jsonData = await this.fetchJson<PolicyDto>(url);
        return jsonData;
    }

    public async addPolicy(blueprint: Blueprint, policy: PolicyCreationDto): Promise<PolicyDto> {
        const url = templateLink(blueprint._links.policies.href, {});
        const jsonData = await this.fetchJson<PolicyDto>(unsafeCastLink(url), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(policy),
        });
        return jsonData;
    }

    public async updatePolicy(policy: PolicyDto, update: PolicyUpdateDto): Promise<PolicyDto> {
        const url = policy._links.self.href;

        const jsonData = await this.fetchJson(url, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(update),
        });
        return jsonData;
    }

    public async deletePolicy(policy: PolicyDto): Promise<void> {
        const url = policy._links.self.href;
        await this.fetch(url, {
            method: 'DELETE',
        });
    }

    public async getPolicyConditionSuggestion(
        blueprint: Blueprint,
        entity: string,
        data: {
            description?: PolicyConditionSuggestionDescription;
            condition?: ExpressionDto;
        } = {},
    ): Promise<PolicyConditionSuggestion> {
        // FIXME: use a proper templated link for conditions suggestions
        const url =
            templateLink(blueprint._links.policy.href, {
                policyId: 'conditions-suggest',
            }) +
            '?entity=' +
            entity;

        if (data.condition || data.description) {
            return await this.fetchJson<PolicyConditionSuggestion>(unsafeCastLink(url), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });
        } else {
            return await this.fetchJson<PolicyConditionSuggestion>(unsafeCastLink(url));
        }
    }

    public async getApplications(project: Project): Promise<Application[]> {
        // const jsonData = await this.fetchJson<ListModel<Application, "applications">>(project._links.applications.href);
        const jsonData = await this.fetchJson(project._links.applications.href);
        return jsonData._embedded?.applications ?? [];
    }

    public async getApplication(application: Application): Promise<Application> {
        return await this.fetchJson(application._links.self.href);
    }

    public async deleteApplication(application: Application): Promise<void> {
        const { url, method } = resolveTemplate(application, 'delete').request;
        await this.fetch(url, {
            method,
        });
    }

    public async getApplicationConfig(application: Application): Promise<ApplicationConfig> {
        return await this.fetchJson(application._links.config.href);
    }

    public async getApplicationWebapps(application: Application): Promise<ApplicationWebapp[]> {
        const jsonData = await this.fetchJson(application._links.webapps.href);
        return jsonData._embedded?.webapps ?? [];
    }

    public async updateApplicationConfig(config: ApplicationConfig): Promise<void> {
        const { url, method } = resolveTemplate(config, 'put').request;
        await this.fetchJson(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(config),
        });
    }

    public async addApplication(project: Project, applicationName: string, zone: Zone): Promise<void> {
        const template = resolveTemplate(zone, 'createApplication');

        const projectBookmark = project._links.bookmark.href;
        const projectRef = projectBookmark.substring(projectBookmark.indexOf('/permalink'));
        await this.fetch(template.request.url, {
            method: template.request.method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: applicationName,
                project: projectRef,
            }),
        });
    }

    public async getDeploymentsForApplication(application: Application): Promise<Deployment[]> {
        let jsonData = await this.fetchJson(application._links.deployments.href);
        let deployments = jsonData?._embedded?.deployments ?? [];
        deployments.sort(
            (a: Deployment, b: Deployment) => new Date(a.created).getTime() - new Date(b.created).getTime(),
        );
        return deployments;
    }

    public async deployChangesetToApp(application: Application, csLink: TypedLink<Changeset>): Promise<void> {
        let changeset = await this.getChangeset(csLink);

        const deployTemplate = resolveTemplate(application, 'deploy');

        await this.fetchJson(deployTemplate.request.url, {
            method: deployTemplate.request.method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                changeset: changeset._links.bookmark.href as string,
            }),
        });
    }

    public async getZone(zoneLink: TypedLink<Zone>): Promise<Zone> {
        return await this.fetchJson(zoneLink);
    }

    public async getZones(organization: Organization): Promise<Zone[]> {
        const data = await this.fetchJson(organization._links.zones.href);
        return data?._embedded?.zones ?? [];
    }

    public async getEventHandlers(blueprint: Blueprint): Promise<EventHandlerDto[]> {
        const jsonData = await this.fetchJson(blueprint._links.webhooks.href);
        return jsonData._embedded?.eventhandlers ?? [];
    }

    public async addEventHandler(
        blueprint: Blueprint,
        eventHandler: EventHandlerCreationDto,
    ): Promise<EventHandlerDto> {
        const url = templateLink(blueprint._links.webhooks.href, {});
        const jsonData = await this.fetchJson<EventHandlerDto>(unsafeCastLink(url), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(eventHandler),
        });
        return jsonData;
    }

    public async updateEventHandler(
        eventhandler: EventHandlerDto,
        update: EventHandlerUpdateDto,
    ): Promise<EventHandlerDto> {
        const url = eventhandler._links.self.href;

        const jsonData = await this.fetchJson(url, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(update),
        });
        return jsonData;
    }

    public async deleteEventHandler(eventhandler: EventHandlerDto): Promise<void> {
        const url = eventhandler._links.self.href;
        await this.fetch(url, {
            method: 'DELETE',
        });
    }

    public async inviteUser(inviteList: OrganizationInviteList, email: string): Promise<void> {
        const { url, method } = resolveTemplate(inviteList, 'invite').request;

        await this.fetch(url, {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email }),
        });
    }

    public async getOrganizationInvites(org: Organization): Promise<OrganizationInviteList> {
        const jsonData = await this.fetchJson(org._links.invitations.href);
        return jsonData;
    }

    public async deleteOrganizationInvite(invite: OrganizationInvite): Promise<void> {
        const { url, method } = resolveTemplate(invite, 'cancel').request;
        await this.fetchJson(url, {
            method,
        });
    }

    public async getUserInvites(user: User): Promise<UserInvite[]> {
        const jsonData = await this.fetchJson(user._links.invitations!.href);
        return jsonData._embedded?.invitations ?? [];
    }

    public async rejectUserInvite(invite: UserInvite): Promise<void> {
        const { url, method } = resolveTemplate(invite, 'reject').request;
        await this.fetchJson(url, {
            method,
        });
    }

    public async acceptUserInvite(invite: UserInvite): Promise<void> {
        const { url, method } = resolveTemplate(invite, 'accept').request;
        await this.fetchJson(url, {
            method,
        });
    }

    public async getRealms(zone: Zone): Promise<IamRealm[]> {
        const jsonData = await this.fetchJson(zone._links.iam.href);
        return jsonData._embedded?.realms ?? [];
    }

    public async manageRealm(realm: IamRealm): Promise<IamRealm> {
        const { url, method } = resolveTemplate(realm, 'manage').request;
        return await this.fetchJson(url, {
            method,
        });
    }

    public async getRealmUsers(link: TypedLink<IamUserList>): Promise<IamUserList> {
        return await this.fetchJson(link);
    }

    public async getRealmGroups(link: TypedLink<IamGroupList>): Promise<IamGroupList> {
        return await this.fetchJson(link);
    }

    public async getRealm(realmId: string): Promise<IamRealm> {
        const url = `${this.baseUrl}iam/${realmId}`;
        return await this.fetchJson<IamRealm>(unsafeCastLink(url));
    }

    public async getRealmOverview(realm: IamRealm): Promise<IamRealmOverview> {
        const url = realm._links.overview.href;
        return await this.fetchJson<IamRealmOverview>(url);
    }

    public async getRealmUser(realm: IamRealm, userId: string): Promise<IamUser> {
        const url = templateLink(realm._links.user.href, {
            userId: userId,
        });
        return await this.fetchJson(url);
    }

    public async getRealmUserGroups(user: IamUser): Promise<IamUserGroupList> {
        const url = templateLink(user._links.groups.href, { id: user.userId });
        return await this.fetchJson(url);
    }

    public async joinRealmGroups(groupList: IamUserGroupList, groups: TypedLink<IamGroup>[]): Promise<void> {
        const { url, method } = resolveTemplate(groupList, 'join-group').request;

        await this.fetch(url, {
            method,
            headers: { 'Content-Type': 'text/uri-list' },
            body: convertArrayToUriList(groups),
        });
    }

    public async createRealmUser(templateUser: IamUserList, user: IamCreateUser): Promise<void> {
        const { url, method } = resolveTemplate(templateUser, 'default').request;
        await this.fetch(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user),
        });
    }

    public async removeRealmGroupMember(member: IamGroupMember): Promise<void> {
        const { url, method } = resolveTemplate(member, 'remove-member').request;
        await this.fetch(url, {
            method,
        });
    }

    public async leaveRealmGroup(group: IamUserGroup): Promise<void> {
        const { url, method } = resolveTemplate(group, 'leave-group').request;
        await this.fetch(url, {
            method,
        });
    }

    public async editRealmUser(templateUser: IamUser, user: IamEditUser): Promise<void> {
        const { url, method } = resolveTemplate(templateUser, 'default').request;
        await this.fetch(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user),
        });
    }

    public async getRealmGroup(realm: IamRealm, groupId: string): Promise<IamGroup> {
        const url = templateLink(realm._links.group.href, {
            groupId: groupId,
        });
        return await this.fetchJson(url);
    }

    public async createRealmGroup(templateGroup: IamGroupList, group: IamCreateGroup): Promise<void> {
        const { url, method } = resolveTemplate(templateGroup, 'default').request;
        await this.fetch(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(group),
        });
    }

    public async editRealmGroup(templateGroup: IamGroup, group: IamCreateGroup): Promise<void> {
        const { url, method } = resolveTemplate(templateGroup, 'edit').request;
        await this.fetch(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(group),
        });
    }

    public async getRealmGroupMembers(group: IamGroup): Promise<IamGroupMemberList> {
        const url = templateLink(group._links.members.href, {
            id: group.groupId,
        });
        return await this.fetchJson(url);
    }

    public async addRealmGroupMembers(memberList: IamGroupMemberList, users: IamUser[]): Promise<void> {
        const { url, method } = resolveTemplate(memberList, 'add-member').request;
        const userLinks = users.map((item) => item._links.self.href);

        await this.fetch(url, {
            method,
            headers: { 'Content-Type': 'text/uri-list' },
            body: convertArrayToUriList(userLinks),
        });
    }

    public async deleteRealmGroup(group: IamGroup): Promise<void> {
        const { url, method } = resolveTemplate(group, 'delete').request;
        await this.fetch(url, {
            method,
        });
    }

    public async getRealmAttributes(realm: IamRealm): Promise<IamAttributeList> {
        const url = realm._links.attributes.href;
        return await this.fetchJson(url);
    }

    public async createRealmAttribute(
        templateAttribute: IamAttributeList,
        attribute: IamCreateAttribute,
    ): Promise<void> {
        const { url, method } = resolveTemplate(templateAttribute, 'default').request;
        await this.fetch(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(attribute),
        });
    }

    public async deleteRealmAttribute(attribute: IamAttribute): Promise<void> {
        const { url, method } = resolveTemplate(attribute, 'delete').request;
        await this.fetch(url, {
            method,
        });
    }

    public async resetIamRealmUserPassword(user: IamUser): Promise<void> {
        const { url, method } = resolveTemplate(user, 'reset-password').request;
        await this.fetch(url, {
            method,
        });
    }

    public async renameIamRealm(realm: IamRealm, newName: string): Promise<void> {
        const { url, method } = resolveTemplate(realm, 'default').request;
        await this.fetch(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: newName }),
        });
    }

    public async getBlueprintFeatures(blueprint: Blueprint): Promise<Feature[]> {
        const url = blueprint._links.features.href;
        const jsonData = await this.fetchJson(url);
        return jsonData?._embedded?.features ?? [];
    }

    public async recommendedFeatureAction(feature: Feature): Promise<void> {
        const { url, method } = resolveTemplate(feature, 'recommended').request;
        await this.fetch(url, {
            method,
        });
    }

    public async getApplicationHealthInfo(app: Application): Promise<ApplicationHealth> {
        const url = app._links.health;
        return await this.fetchJson(url.href);
    }

    private getToken(): String | null {
        var oidcKey = `oidc.user:${this.authority}:${this.clientId}`;
        const oidcStorage = sessionStorage.getItem(oidcKey);
        if (!oidcStorage) {
            return null;
        }

        var oidcUser = OidcUser.fromStorageString(oidcStorage);
        return oidcUser?.access_token;
    }

    public async saveSchemaLayout(blueprint: Blueprint, flow: ReactFlowJsonObject<any, any>): Promise<void> {
        const name = `schema_${blueprint.project}_${blueprint.name}`;

        localStorage.setItem(name, JSON.stringify(flow));
    }

    public async schemaLayout(blueprint: Blueprint): Promise<ReactFlowJsonObject<any, any>> {
        const name = `schema_${blueprint.project}_${blueprint.name}`;
        const schema = localStorage.getItem(name);
        const flow = schema ? JSON.parse(schema) : '';

        return flow;
    }

    public async getThreads(blueprint: Blueprint): Promise<ThreadList> {
        // places calling this should only render when the assistant link is present
        const url = blueprint._links.assistant!.href;
        return await this.fetchJson(url);
    }

    public async createThread(threads: ThreadList): Promise<Thread> {
        const { url, method } = resolveTemplate(threads, 'startThread').request;
        return await this.fetchJson(url, {
            method,
        });
    }

    public async getMessageList(thread: Thread): Promise<MessageList> {
        return await this.fetchJson<MessageList>(thread._links.messages.href);
    }

    public async createMessage(conversation: MessageList, question: string, file?: File): Promise<Message> {
        const template = resolveTemplate(conversation, 'addMessage');
        const { url, method } = template.request;
        const properties = template.properties;

        // Create a new FormData object
        const formData = new FormData();

        // Add properties to the form data
        properties.forEach((property) => {
            if (property.name === 'question') {
                formData.append(property.name, question !== '' ? question : 'I attached a file');
            } else if (property.name === 'file') {
                if (file) {
                    formData.append(property.name, file);
                }
            }
        });

        // Use the existing fetchJson method
        return await this.fetchJson<Message>(url, {
            method,
            body: formData,
        });
    }

    private async fetch(url: string, init?: RequestInit | undefined): Promise<Response> {
        const token = this.getToken();
        let method = init?.method ?? 'GET';
        if (!token) {
            throw new HttpError(method, url, 'no token');
        }

        init = {
            ...init,
            headers: {
                ...init?.headers,
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await fetch(url, init);
        if (!response.ok) {
            throw await HttpJsonError.tryFromResponse(method, url, response);
        }

        return response;
    }

    public async fetchJson<T>(url: TypedLink<T>, init?: RequestInit): Promise<T> {
        const newHeaders = {
            ...init?.headers,
            Accept: 'application/prs.hal-forms+json',
        };
        const response = await this.fetch(url, {
            ...init,
            headers: newHeaders,
        });

        return await response.json();
    }
}
